

















import Vue from "vue";
import { loginRequest } from "@/configs/authConfig";
import { AddressesClient, ApiException, Hit } from "@/clients/clients";

interface ISelectItem {
  text: string;
  value: string | number;
  disabled?: boolean;
  divider?: boolean;
  header?: string;
}

export default Vue.extend({
  name: "AppAddressAutoComplete",
  data: () => ({
    loading: false,
    search: null,
    items: [] as ISelectItem[],
    selected: null as number | string | null,
    errorMessage: null as string | null,
  }),
  methods: {
    onClear(): void {
      this.items = [];
    },
    async getAddresses(query: string): Promise<void> {
      if (!query.length) return;
      this.loading = true;
      this.errorMessage = null;
      try {
        const token = await this.$auth.acquireTokenSilent(loginRequest);
        const client = this.$httpClientFactory(
          AddressesClient,
          token.accessToken
        );
        const results = await client.getAddresses(encodeURIComponent(query));
        const addresses =
          results.result?.hits
            ?.map((item: Hit) => ({
              text: item.suggestion ?? "",
              value: item.udprn ?? -1,
            }))
            .filter((p) => p.text.length && p.value > 0) ?? [];
        this.items = [
          ...addresses,
          {
            text: "Add manually",
            value: 0,
          },
        ];
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    async search(newVal: string | null, oldVal: string | null): Promise<void> {
      if (newVal === "Add manually" || newVal === oldVal || newVal === null)
        return;
      await this.getAddresses(newVal);
    },
    async selected(
      newVal: number | string | null,
      oldVal: number | string | null
    ): Promise<void> {
      if (newVal === oldVal || newVal === null) return;
      if (newVal != oldVal) this.$emit("change", newVal);
    },
  },
});
